// Generated by Framer (d9af232)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["xwTVDqrAo", "YaGwwpvV7", "lnsHmofO5", "Wq_bGUoS1", "OMZ3ci0VV"];

const serializationHash = "framer-hYpkc"

const variantClassNames = {lnsHmofO5: "framer-v-1h1h6gq", OMZ3ci0VV: "framer-v-16zq9kk", Wq_bGUoS1: "framer-v-1a7rwx0", xwTVDqrAo: "framer-v-1xygti0", YaGwwpvV7: "framer-v-3qg1m"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.35, ease: [0, 0, 1, 1], type: "tween"}

const transition2 = {delay: 0, duration: 1, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "xwTVDqrAo", "Variant 2": "YaGwwpvV7", "Variant 3": "lnsHmofO5", "Variant 4": "Wq_bGUoS1", "Variant 5": "OMZ3ci0VV"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "xwTVDqrAo"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "xwTVDqrAo", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearhj4zqr = activeVariantCallback(async (...args) => {
await delay(() => setVariant("YaGwwpvV7"), 1)
})

const onAppearym5xrz = activeVariantCallback(async (...args) => {
await delay(() => setVariant("lnsHmofO5"), 1000)
})

const onAppear1o0g4qa = activeVariantCallback(async (...args) => {
await delay(() => setVariant("Wq_bGUoS1"), 350)
})

const onAppearhridim = activeVariantCallback(async (...args) => {
await delay(() => setVariant("OMZ3ci0VV"), 1000)
})

const onAppear7bafpr = activeVariantCallback(async (...args) => {
await delay(() => setVariant("YaGwwpvV7"), 350)
})

useOnVariantChange(baseVariant, {default: onAppearhj4zqr, lnsHmofO5: onAppear1o0g4qa, OMZ3ci0VV: onAppear7bafpr, Wq_bGUoS1: onAppearhridim, YaGwwpvV7: onAppearym5xrz})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({Wq_bGUoS1: {value: transition2}, YaGwwpvV7: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1xygti0", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"xwTVDqrAo"} ref={ref ?? ref1} style={{backgroundColor: "rgba(160, 171, 251, 0.18)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} {...addPropertyOverrides({lnsHmofO5: {"data-framer-name": "Variant 3"}, OMZ3ci0VV: {"data-framer-name": "Variant 5"}, Wq_bGUoS1: {"data-framer-name": "Variant 4"}, YaGwwpvV7: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-12hqum8"} data-framer-name={"Moving Light"} layoutDependency={layoutDependency} layoutId={"VSwehfisV"} style={{background: "radial-gradient(50% 50% at 50% 50%, rgb(206, 212, 253) 29.72972972972973%, rgba(255, 255, 255, 0) 100%)"}}/><motion.div className={"framer-ndfk6x"} data-framer-name={"Container"} layoutDependency={layoutDependency} layoutId={"lRfbilRIm"} style={{background: "linear-gradient(180deg, var(--token-8a213278-31dd-424d-8fb4-5da7d0d1f079, rgb(25, 78, 255)) /* {\"name\":\"Blue 55\"} */ 0%, var(--token-e6711ee2-0d80-49b0-8030-a95cbf836a37, rgb(0, 59, 255)) /* {\"name\":\"Blue 50\"} */ 100%)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hYpkc.framer-vhz000, .framer-hYpkc .framer-vhz000 { display: block; }", ".framer-hYpkc.framer-1xygti0 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 45px; justify-content: center; overflow: hidden; padding: 1px; position: relative; width: 182px; will-change: var(--framer-will-change-override, transform); }", ".framer-hYpkc .framer-12hqum8 { flex: none; height: 44px; left: -17px; overflow: visible; position: absolute; top: -20px; width: 70px; z-index: 1; }", ".framer-hYpkc .framer-ndfk6x { flex: 1 0 0px; height: 1px; overflow: visible; position: relative; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-hYpkc.framer-1xygti0 { gap: 0px; } .framer-hYpkc.framer-1xygti0 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-hYpkc.framer-1xygti0 > :first-child { margin-top: 0px; } .framer-hYpkc.framer-1xygti0 > :last-child { margin-bottom: 0px; } }", ".framer-hYpkc.framer-v-3qg1m .framer-12hqum8 { left: unset; right: -20px; }", ".framer-hYpkc.framer-v-1h1h6gq .framer-12hqum8 { bottom: -20px; left: unset; right: -20px; top: unset; }", ".framer-hYpkc.framer-v-1a7rwx0 .framer-12hqum8 { bottom: -20px; left: -20px; top: unset; width: 50px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 45
 * @framerIntrinsicWidth 182
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"YaGwwpvV7":{"layout":["fixed","fixed"]},"lnsHmofO5":{"layout":["fixed","fixed"]},"Wq_bGUoS1":{"layout":["fixed","fixed"]},"OMZ3ci0VV":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Frameru_viH9qGm: React.ComponentType<Props> = withCSS(Component, css, "framer-hYpkc") as typeof Component;
export default Frameru_viH9qGm;

Frameru_viH9qGm.displayName = "Helper/Moving BG 2";

Frameru_viH9qGm.defaultProps = {height: 45, width: 182};

addPropertyControls(Frameru_viH9qGm, {variant: {options: ["xwTVDqrAo", "YaGwwpvV7", "lnsHmofO5", "Wq_bGUoS1", "OMZ3ci0VV"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4", "Variant 5"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Frameru_viH9qGm, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})